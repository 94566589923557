import React from 'react';
import '../styles/Bottom.css';

const Bottom = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bottom">
      <p>© {currentYear} Ilias Pavlidakis. All rights reserved.</p>
    </div>
  );
};

export default Bottom;
