import React, { useEffect, useState } from 'react';
import '../styles/Description.css';
import { collection, getDocs } from 'firebase/firestore';  // Import Firestore methods
import { database } from './firebase';  // Import the Firestore instance

const Description = () => {
  const [descriptionLines, setDescriptionLines] = useState([]);

  // Fetch data from your backend using the `databases.listDocuments` method
  useEffect(() => {
    const fetchDescriptionLines = async () => {
      try {
        const querySnapshot = await getDocs(collection(database, "description"));
        const lines = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(lines);
        setDescriptionLines(lines.sort((a, b) => a.order - b.order));
      } catch (error) {
        console.error("Error fetching description lines:", error);
      }
    };

    fetchDescriptionLines();
  }, []);
  return (
    <div className="description-section">
      <h2>About</h2>
      <div className="description-content">
        {descriptionLines.length > 0 ? (
          descriptionLines.map((descriptionLine) => (
            <p key={descriptionLine.id}>{descriptionLine.content}</p>
          ))
        ) : (
          <p key="loading">Loading description...</p>
        )}
      </div>
    </div>
  );
};

export default Description;
