import React, { useEffect, useState } from 'react';
import '../styles/Projects.css';
import { collection, getDocs } from 'firebase/firestore';
import { database } from './firebase';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        console.log("Fetching projects...");
        const querySnapshot = await getDocs(collection(database, "projects"));
        console.log("Query snapshot:", querySnapshot);
        const projectsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Projects data:", projectsData);
        setProjects(projectsData.sort((a, b) => a.order - b.order));
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  console.log("Current projects state:", projects);
  console.log("Loading state:", loading);

  if (loading) {
    return null;
  }

  if (projects.length === 0) {
    return null;
  }

  return (
    <div className="projects">
      <h2>Projects</h2>
      {projects.map((project) => (
        <div key={project.id} className="project-card">
          <h3>{project.name}</h3>
          <p>{project.description}</p>
          <a 
            href={project.link} 
            target="_blank" 
            rel="noopener noreferrer"
            className="project-link"
            style={{ backgroundColor: project.color || '#000000' }}
          >
            Visit {project.name}
          </a>
        </div>
      ))}
    </div>
  );
};

export default Projects; 