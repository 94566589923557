import React from 'react';
import '../styles/App.css';
import { ThemeProvider } from './ThemeContext';
import Top from './Top';
import Content from './Content';
import Bottom from './Bottom';

function App() {
  return (
    <ThemeProvider>
      <div className="App">
        <div className="content-wrapper">
          <div className="main-content">
            <Top />
            <Content />
          </div>
        </div>
        <Bottom />
      </div>
    </ThemeProvider>
  );
}

export default App;
