import React from 'react';
import '../styles/Content.css';
import profileImage from '../assets/headshot.png';  // From src

const ProfileImage = () => {
  return (
    <img
      className="profile-image"
      src={profileImage}
      alt="Ilias Pavlidakis"
      loading="lazy"
      decoding="async"
    />
  );
};

export default ProfileImage;
