import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

// Initial execution
setVh();

// Recalculate on window resize with debounce
let resizeTimeout;
const handleResize = () => {
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(setVh, 100);
};

window.addEventListener('resize', handleResize);

// Cleanup function
const cleanup = () => {
  window.removeEventListener('resize', handleResize);
  clearTimeout(resizeTimeout);
};

// Cleanup on unmount
window.addEventListener('unload', cleanup);