import React from 'react';
import '../styles/Content.css';
import ProfileImage from './ProfileImage';
import Description from './Description';
import SocialLinks from './SocialLinks';
import Projects from './Projects';

const Content = () => {
  return (
    <div className="content">
       <ProfileImage />
       <Description />
       <Projects />
       <SocialLinks />
    </div>
  );
};

export default Content;
